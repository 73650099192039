<template>
  <v-card
    class="pb-3"
  >
    <v-card-title>
      Historik
      <v-spacer></v-spacer>
      <v-btn icon :loading="loading" @click="getLogs">
        <v-icon>mdi-refresh</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text v-if="loading">
      <v-progress-linear indeterminate></v-progress-linear>
    </v-card-text>

    <v-timeline
      v-else-if="logs.length"
      align-top
      dense
      style="max-height: 1000px; overflow-y: auto;"
    >
      <v-timeline-item
        v-for="item in logs"
        :key="item.id"
        small
        class="pr-4"
        :color="item.level.toLowerCase()"
      >
        <div class="d-flex justify-space-between align-center">
          <v-chip
            small
            :color="item.level.toLowerCase()"
          >
            {{ item.level }}
          </v-chip>
          <div class="text-caption grey--text">
            {{ item.createdOn | formatDateTime }}
          </div>
        </div>
        <div class="mt-2 text-caption">
          <div v-if="item.event === 'SupplierPartImport'">
            <div
              v-for="(line, index) in item.message.split(/\r\n/).slice(-10, -1)"
              :key="index"
              v-text="line"
              class="text-truncate"
              style="max-width: 400px;"
            />

            <v-dialog scrollable>
              <template #activator="{ attrs, on }">
                <v-btn
                  outlined
                  small
                  v-bind="attrs"
                  v-on="on"
                  class="mt-2"
                >
                  Show more
                </v-btn>
              </template>

              <v-card>
                <v-card-title>
                  Import details
                  <v-chip class="ml-4" label :color="item.level.toLowerCase()">
                    {{ item.level }}
                  </v-chip>
                </v-card-title>
                <v-card-text class="text-caption">
                  <div v-for="(line, index) in item.message.split(/\r?\n/)" :key="index" class="d-flex" style="font-family: monospace;">
                    <span style="width: 30px;">{{ index + 1 }}</span>
                    <span>{{ line }}</span>
                  </div>
                </v-card-text>
              </v-card>
            </v-dialog>
          </div>
          <div v-else-if="item.message.length > 600" class="d-flex justify-space-between align-center">
            <div>
              {{ item.message.slice(0,110) }}...
            </div>

            <v-dialog scrollable>
              <template #activator="{ attrs, on }">
                <v-btn
                  outlined
                  small
                  v-bind="attrs"
                  v-on="on"
                >
                  Show more
                </v-btn>
              </template>

              <v-card>
                <v-card-title>
                  Import details
                  <v-chip class="ml-4" label :color="item.level.toLowerCase()">
                    {{ item.level }}
                  </v-chip>
                </v-card-title>
                <v-card-text class="text-caption">
                  <div v-for="(line, index) in item.message.split(/\r?\n/)" :key="index" class="d-flex" style="font-family: monospace;">
                    <span style="width: 30px;">{{ index + 1 }}</span>
                    <span>{{ line }}</span>
                  </div>
                </v-card-text>
              </v-card>
            </v-dialog>
          </div>
          <div v-else>
            {{ item.message }}.
          </div>
        </div>
      </v-timeline-item>
    </v-timeline>

    <v-card-text v-else>
      Ingen historik at vise.
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'SupplierLog',
  props: {
    supplierId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      loading: true,
      show: false,
      logs: []
    }
  },
  methods: {
    async getLogs() {
      try {
        const { data } = await this.$AuthService.api.get('log', {
          params: {
            sourceEntityType: 'supplier',
            sourceEntityId: this.supplierId
          }
        })
    
        this.logs = data
      } catch (error) {
        console.error(error)
      } finally {
        this.loading = false
      }
    }
  },
  mounted() {
    this.getLogs()
  }
}
</script>