<template>
  <div>
    <Loading :loading="loading" text="Henter leverandør" />
    <v-container fluid>
      <v-toolbar flat color="transparent">
        <v-btn icon @click="$router.go(-1)">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        <v-toolbar-title>
          Rediger leverandør
        </v-toolbar-title>

        <v-spacer></v-spacer>

        <v-switch
          v-model="supplier.active"
          :label="supplier.active ? 'Aktiv' : 'Inaktiv'"
          hide-details
          inset
          color="success"
          class="mr-4"
        />

        <v-btn
          text
          class="mr-4"
          :loading="importing"
          @click="showConfigDrawer = true"
        >
          Import konfiguration
          <v-icon right> mdi-dev-to </v-icon>

          <template #loader>
            <div class="flex-grow-1 d-flex flex-column justify-space-between">
              <div> Importerer </div>
              <v-progress-linear
                indeterminate
                color="primary"
              />
            </div>
          </template>
        </v-btn>

        <v-btn
          color="primary"
          :loading="saving"
          @click="update"
        >
          Gem
          <v-icon right> mdi-content-save-outline </v-icon>
        </v-btn>
      </v-toolbar>
      <v-row>
        <v-col cols="12" sm="8">
          <v-card>
            <v-form ref="form" v-model="valid">
              <v-card-text>
                <v-text-field
                  v-model="supplier.title"
                  label="Navn"
                  :rules="[ v => !!v || 'Skal udfyldes' ]"
                />
                <v-text-field
                  v-model="supplier.account"
                  label="Kontonummer"
                  clearable
                />
                <v-text-field
                  v-model="supplier.webUrl"
                  label="Website"
                  clearable
                />
                <v-text-field
                  v-model="supplier.webPartTemplateUrl"
                  label="Vare URL skabelon"
                  type="url"
                  clearable
                />
                <v-text-field
                  v-model="supplier.webLogin"
                  label="Brugernavn"
                  clearable
                />
                <v-text-field
                  v-model="supplier.webPassword"
                  label="Password"
                  clearable
                />
                <v-textarea
                  v-model="supplier.ourContactPerson"
                  label="TPA leverandørkontakt"
                  :rows="1"
                  auto-grow
                  clearable
                />
                <v-textarea
                  v-model="supplier.contactInfo"
                  label="Kontaktinfo"
                  :rows="2"
                  auto-grow
                  clearable
                />
                <v-textarea
                  v-model="supplier.dataUpdateProcess"
                  label="Prisopdatering"
                  :rows="2"
                  auto-grow
                  clearable
                />
                <v-text-field
                  v-model.number="supplier.stalePriceLimitInDays"
                  label="Kostpris forældes efter"
                  suffix="dage"
                  :hint="`Standard: ${config.supplierCostPriceStaleDays} dage`"
                  clearable
                />
                <v-checkbox label="Kan definere kostpriser" v-model="supplier.canDefineCostPrice"></v-checkbox>
                <v-checkbox label="Kan definere listepriser" v-model="supplier.canDefineListPrice"></v-checkbox>
              </v-card-text>
              <v-card-title>
                Betaling og bestilling
              </v-card-title>
              <v-card-text>
                <v-textarea
                  v-model="supplier.paymentTerms"
                  label="Betalingsbetingelser"
                  placeholder="F.eks. '30 dage'"
                  :rows="2"
                  auto-grow
                />
                <v-textarea
                  v-model="supplier.orderProcess"
                  label="Bestillingsmetode"
                  :rows="2"
                  auto-grow
                />
                <v-textarea
                  v-model="supplier.transportTerms"
                  label="Leveringsbetingelser (Fragt)"
                  :rows="2"
                  auto-grow
                />
                <v-text-field
                  v-model="supplier.contractCompany"
                  label="Nuværende indkøbsfirma"
                />
                <v-textarea
                  v-model="supplier.otherCompaniesOnContract"
                  label="Koncern indkøbspartnere"
                  :rows="2"
                  auto-grow
                />
              </v-card-text>
              <v-card-title>
                Kredit og rabat
              </v-card-title>
              <v-card-text>
                <v-text-field
                  v-model.number="supplier.creditAmount"
                  type="number"
                  label="Kreditbeløb"
                />
                <v-textarea
                  v-model="supplier.discountTerms"
                  label="Rabataftale beskrivelse"
                  :rows="2"
                  auto-grow
                />
                <v-menu
                  v-model="discountDateMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  top
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      :value="$options.filters.formatDate(supplier.discountTermsDate)"
                      label="Rabataftale dato"
                      prepend-inner-icon="mdi-calendar"
                      readonly
                      clearable
                      v-bind="attrs"
                      v-on="on"
                      @click:clear="supplier.discountTermsDate = null"
                    >
                      <template #default>
                        {{ supplier.discountTermsDate | formatDate }}
                      </template>
                    </v-text-field>
                  </template>
                  <v-date-picker
                    v-model="_discountTermsDate"
                    @input="discountDateMenu = false"
                    :first-day-of-week="1"
                  ></v-date-picker>
                </v-menu>
                <v-menu
                  v-model="negotiationDateMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      :value="$options.filters.formatDate(supplier.nextNegotiationDate)"
                      label="Udløb / Næste forhandling"
                      prepend-inner-icon="mdi-calendar"
                      readonly
                      clearable
                      v-bind="attrs"
                      v-on="on"
                      @click:clear="supplier.nextNegotiationDate = null"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="_nextNegotiationDate"
                    @input="negotiationDateMenu = false"
                    :first-day-of-week="1"
                  ></v-date-picker>
                </v-menu>
              </v-card-text>
            </v-form>
          </v-card>
        </v-col>

        <v-col>
          <v-row>
            <v-col>
              <v-card>
                <v-card-text>
                  <v-textarea
                    v-model="supplier.comments"
                    label="Note"
                    :rows="2"
                    auto-grow
                  />
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <SupplierLog ref="log" :supplierId="$route.params.id" />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>

    <v-navigation-drawer
      v-model="showConfigDrawer"
      right
      app
      temporary
      width="50%"
      class="pt-16"
    >
      <v-row>
        <v-col>
          <v-card flat color="transparent">
            <v-card-title>
              Importkonfiguration

              <v-spacer></v-spacer>

              <v-btn
                :loading="importing"
                class="mr-3"
                @click="triggerImport"
              >
                Kør import
              </v-btn>

              <v-btn :loading="updatingImportConfig" @click="updateImportConfig">
                Gem konfiguration
                <v-icon right>mdi-content-save-outline</v-icon>
              </v-btn>

              <v-btn
                icon
                @click="showConfigDrawer = false"
                class="ml-2"
              >
                <v-icon> mdi-close </v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text>
              <v-text-field
                v-model="supplier.partImporterType"
                label="Import type"
                outlined
                hint="Jeg håber du ved hvad du laver.."
                clearable
              />
              <div class="d-flex align-center" style="gap: 1rem;">
                <v-file-input
                  v-model="file"
                  :disabled="!supplier.partImporterType || !supplier.partImporterConfig"
                  label="Ny fil med produkter og priser"
                  chips
                  outlined
                  show-size
                  hide-details
                />

                <v-menu
                  offset-x
                  :close-on-click="false"
                  left
                >
                  <template #activator="{ attrs, on }">
                    <v-btn v-bind="attrs" v-on="on" :disabled="!file || !supplier.partImporterType || !supplier.partImporterConfig" :loading="uploading">
                      Upload
                      <v-icon right>mdi-upload-outline</v-icon>
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-title>
                      Er du sikker?
                    </v-card-title>
                    <v-card-text>
                      Dette vil overskrive den fil der bruges til at opdatere varer og deres kostpriser for leverandøren. Dette bruges til at beregne listepriser og kundepriser i portalen.
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn text color="secondary">Nej, jeg fortryder</v-btn>
                      <v-btn color="primary" @click="uploadPriceList">Ja, overskriv fil</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-menu>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <prism-editor
            v-model="supplier.partImporterConfig"
            :highlight="highlighter"
            line-numbers
            class="config-editor"
          />
        </v-col>
      </v-row>
    </v-navigation-drawer>
  </div>
</template>

<script>
import Loading from '@/components/Loading';
import SupplierLog from '@/components/SupplierLog.vue';
import { PrismEditor } from 'vue-prism-editor';
import 'vue-prism-editor/dist/prismeditor.min.css';
import { highlight, languages } from 'prismjs/components/prism-core';
import 'prismjs/components/prism-json';
import 'prismjs/themes/prism-tomorrow.css';
import config from '@/assets/config.json'

export default {
  name: 'SupplierUpsert',
  components: {
    Loading,
    PrismEditor,
    SupplierLog
  },
  data() {
    return {
      discountDateMenu: false,
      loading: false,
      uploading: false,
      importing: false,
      negotiationDateMenu: false,
      saving: false,
      updatingImportConfig: false,
      config,
      showConfigDrawer: false,
      supplier: {},
      valid: false,
      file: null
    }
  },
  computed: {
    _discountTermsDate: {
      get() {
        return this.moment(this.supplier.discountTermsDate).format('YYYY-MM-DD');
      },
      set(value) {
        this.supplier.discountTermsDate = new Date(value).toISOString();
      }
    },
    _nextNegotiationDate: {
      get() {
        return this.moment(this.supplier.nextNegotiationDate).format('YYYY-MM-DD');
      },
      set(value) {
        this.supplier.nextNegotiationDate = new Date(value).toISOString();
      }
    }
  },
  async mounted() {
    try {
      this.loading = true;

      const response = await this.$AuthService.api.get(`supplier/${this.$route.params.id}`);

      this.supplier = response.data;
    } catch (error) {
      console.error(error);
      this.$notifier.showError({});
    } finally {
      this.loading = false;
    }
  },
  methods: {
    highlighter(code) {
      return highlight(code, languages.json);
    },
    async update() {
      try {
        this.saving = true;

        const response = await this.$AuthService.api.put(`supplier/${this.$route.params.id}`, this.supplier);

        this.supplier = response.data;

        this.$notifier.showSuccess({ message: 'Ændringerne blev gemt' })
      } catch (error) {
        console.error(error);
        this.$notifier.showError({ message: 'Ændringer blev ikke gemt' })
      } finally {
        this.$refs.log.getLogs();
        this.saving = false;
      }
    },
    async triggerImport() {
      try {
        this.importing = true;

        const response = await this.$AuthService.api.post(`supplier/${this.$route.params.id}/update-partsupplierdetails`);

        let text = 'Import kørte uden fejl';

        if (response.data?.supplierPartsUpdateCount != null) {
          text = `Opdaterede ${response.data?.supplierPartsUpdateCount} rækker.`;
        }

        this.$notifier.showSuccess({ message: text });
      } catch (error) {
        console.error(error);
        this.$notifier.showError({ message: 'Import fejlede. Tjek konsollen for detaljer.' });
      } finally {
        this.$refs.log.getLogs();
        this.importing = false;
      }
    },
    async uploadPriceList() {
      this.uploading = true

      try {
        const formData = new FormData()

        formData.append('file', this.file)
        const { data: uploadData } = await this.$AuthService.api.post(`supplier/${this.$route.params.id}/upload-parts`, formData)

        if (!uploadData.success) {
          if (uploadData.errors.length) throw uploadData.errors[0]
          else if (uploadData.message) throw uploadData.message
          else throw 'An error happened trying to upload file!'
        }

        this.$notifier.showSuccess({ message: 'Fil uploadet' })
        this.file = null

        const { data: importData } = await this.$AuthService.api.post(`supplier/${this.$route.params.id}/import-parts`)
        
        if (!importData.success) {
          if (importData.errors.length) throw importData.errors[0]
          else if (importData.message) throw importData.message
          else throw 'An error happened trying to import file!'
        }
        
        this.$notifier.showSuccess({ message: 'Fil importeret' })
      } catch (error) {
        console.error(error)
        this.$notifier.showError(error)
      } finally {
        this.uploading = false;
        this.$refs.log.getLogs();
      }

    },
    async updateImportConfig() {
      this.updatingImportConfig = true
      const { partImporterType, partImporterConfig } = this.supplier
      const model = { partImporterType, partImporterConfig }

      try {
        await this.$AuthService.api.patch(`supplier/${this.$route.params.id}/import-config`, model)

        this.$notifier.showSuccess({ message: 'Konfigurationen blev gemt' });
      } catch (error) {
        console.error(error)
        this.$notifier.showError({ message: 'Der skete en fejl!' });
      } finally {
        this.$refs.log.getLogs();
        this.updatingImportConfig = false
      }
    }
  }
}
</script>

<style>
  .config-editor {
    background: #2d2d2d;
    color: #ccc;

    /* you must provide font-family font-size line-height. Example: */
    font-family: Fira code, Fira Mono, Consolas, Menlo, Courier, monospace;
    font-size: 14px;
    line-height: 1.5;
    padding: 5px;
  }

  .prism-editor__textarea:focus {
    outline: none;
  }
</style>
